import React from 'react';
// import Carousel from '../Carousel';
import { useNavigate } from 'react-router-dom';
import ClassNames from 'clsx';
import Styles from './version.module.scss';


const VERSION = () => {
    
    return (
        <div>
            <h2>Version: {process.env.REACT_APP_VERSION}</h2>
             
         </div>
       
    )
};

export default VERSION;