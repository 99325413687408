import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClassNames from 'clsx';
import Styles from './pg3.module.scss';
import packageJson from '../../package.json';
import StepImg from '../../src/assets/images/prepare-test@2x.png';

const PG3 = () => {
    const navigate = useNavigate();
    const lot: string = `?lot=${new URLSearchParams(window.location.search).get('lot')}`;
    const dt =`&dt=${new URLSearchParams(window.location.search).get('dt')}`; 
    const searchParam:string = lot + dt;
    return (
        <div className={Styles['container']}>
            <div className={Styles['logo-clear-detect']} />
            <div>
                <h2>How it works</h2>
                <p>
                    Please watch this test tutorial for detailed instructions
                </p>
            </div>
            {/* <video controls autoPlay>
                <source src="videos/1.mp4" type="video/mp4" />
            </video> */}
            <video controls id="video1" data-able-player="" preload="auto" poster="#" style={{width: '100%', height: 'auto'}} tabIndex={1} data-chapters-div="chapters">
            <source type="video/webm" src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_no_audio_description.webm" data-desc-src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_with_audio_description.webm" data-orig-src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_no_audio_description.webm" />
        <source type="video/mp4" src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_no_audio_description_small.mp4" data-desc-src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_with_audio_description_small.mp4" data-orig-src="https://maximbio-cleardetect.com/wp-content/uploads/2024/05/NIH_RADx_042324_no_audio_description_small.mp4" />
        
        <track  kind="captions" src="captions/Captions-non-AD_042324.vtt" srcLang="en" />
        
        <track kind="chapters" src="captions/Chapters-non-AD_042324.vtt" srcLang="en" />
        
        
        <track data-desc="" kind="captions" src="captions/Captions-AD_042324.vtt" srcLang="en" label="English" />
        
        <track data-desc="" kind="chapters" src="captions/Chapters-AD_042324.vtt" srcLang="en" />
            </video>
            <div className={Styles['container-button']}>
            <button 
                className={ClassNames(Styles.btn, Styles.regular, Styles.secondary)}
                onClick={() => navigate('/' + searchParam)}
            >
                Back
            </button>
            </div>
           
        </div>
    )
};

export default PG3;