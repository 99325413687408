import React from 'react';
import QRCode from 'react-qr-code';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Button } from '@mui/material';
import PG1 from './pg1';
import PG2 from './pg2/pg2';
import PG3 from './pg3/pg3';
import PG4 from './pg4/pg4';
import VERSION from './version/version';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function QRGenerator() {
  const [BaseURL, setBASEURL] = React.useState<string>(window.location.origin + process.env.PUBLIC_URL);
  const [txt, setTxt] = React.useState<string>('');
  const [soiled, setSoiled] = React.useState(false);
  const [date, setDate] = React.useState<any>(new Date());
  return (
    <div style={{height: '100vh', width: '100vw', display: 'grid', alignItems: 'center', justifyContent: 'center'}}>
      <form onSubmit={(e) => {e.preventDefault(); setSoiled(false)}}>
        BASE URL: <input style={{width: '500px', marginRight: '10px'}}value={BaseURL} onChange={(e) => { setBASEURL(e.target.value); setSoiled(true)}}/> <br /> <br />
              <Button variant="contained"
                  disabled={!BaseURL /*|| !txt || !date*/}

                  type="submit" onClick={() => setSoiled(false)} style={{ marginRight: '10px' }}>Generate QR</Button>
        <input style={{width: '100px', marginRight: '10px'}}value={txt} onChange={(e) => { setTxt(e.target.value); setSoiled(true); }} placeholder='lot#:'/>
        <DatePicker showIcon  selected={date} onChange={(newValue) => { setDate(newValue); setSoiled(true)}}></DatePicker>

      </form>
      <div style={{ height: '340px'}}>
              { soiled === false && txt && date && (
                    <div>
                        <h2 style={{ marginBottom: '50px'}}>QR Code for: <span style={{ color: 'green', fontWeight: 'bold'}}>{`${BaseURL}?lot=${txt}&dt=${date.toISOString().split('T')[0]}`}</span></h2>
                        <QRCode value={`${BaseURL}?lot=${txt}&dt=${date.toISOString().split('T')[0]}`}/>
                    </div>
                  )}
      </div>
    </div>
  );
}

const Loader = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route path='/qrc' Component={QRGenerator}/>
      <Route path='/' Component={PG1} />
      <Route path='/pg2' Component={PG2} />
      <Route path='/pg3' Component={PG3} />
            <Route path='/pg4' Component={PG4} />
            <Route path='/version' Component={VERSION} />
    </Routes>
  </BrowserRouter>
);
export default Loader;

/**
 * value={'https://q11070.github.io/covid-scan-test?lot='+ URL + '&dt=' + date.toISOString().split('T')[0]}
 */
