import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClassNames from 'clsx';
import Styles from './pg2.module.scss';
import StepImg from '../../src/assets/images/testing-components@2x.png';
import packageJson from '../../package.json';
import { debug } from 'util';

const PG2 = () => {
    const navigate = useNavigate();
    const lot: string = `?lot=${new URLSearchParams(window.location.search).get('lot')}`;
    const dt =`&dt=${new URLSearchParams(window.location.search).get('dt')}`; 
    const searchParam: string = lot + dt;
    debugger
    return (

        <div className={Styles['container']}>
            <div className={Styles['logo-clear-detect']} />

            <div className={Styles['step-img']}>
                <img src={StepImg} alt="StepImage"/>
            </div>

            <p>
                <strong>Note:</strong> This kit comes in 1, 2, 4, and 25 test quantities. The number of items supplied in the kit will vary depending on which kit is purchased.
            </p>

            <div className={Styles['container-button']}>
            <button 
                className={ClassNames(Styles.btn, Styles.regular, Styles.secondary)}
                onClick={() => navigate('/' + searchParam)}
            >
                Back
            </button>

            <button 
                className={ClassNames(Styles.btn, Styles.regular, Styles.primary)}
                onClick={() => navigate('/pg3' + searchParam)}
            >
                Show Instructional Video
            </button>
            </div>
           
        </div>

    )
};

export default PG2;