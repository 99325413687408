import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClassNames from 'clsx';
import Styles from './pg1.module.scss';
import { DateTime } from 'luxon'

const Home = () => {
    const navigate = useNavigate();
    //const lot=`${new URLSearchParams(window.location.search).get('lot')}`;
    var lot: any  = `${new URLSearchParams(window.location.search).get('lot')}`;
    var dt =`${new URLSearchParams(window.location.search).get('dt')}`;
    var instructions1 = "";
    var instructions2 = "";
    lot = lot === 'null' ? '' : lot;
    dt = dt === 'null' ? '' : dt;
    console.log(lot);
    console.log(dt);
    const searchParam = '?lot=' + lot + '&dt=' + dt;
    var lotStatus = isNaN(lot) ? lot != "" && lot != 'null' : Number(lot) > 0;
    var lot_hex = parseInt("FFFFFFFF", 16)+1;
    try {
        lot_hex = parseInt(lot, 16);
    }
    catch {
        lotStatus = false;
    }
    if (lot_hex > parseInt("FFFFFFFF", 16)) {
        lotStatus = false;    }
    if (!lotStatus) {
        lot = "Invalid";
    }
    /**
     * Valid ISO 8601 format is YYYY-MM-DD
     * URL: https://www.iso.org/iso-8601-date-and-time-format.html
     * @param inputDate 
     * @returns 
     */

    let isValidISODate = (inputDate: string) => /[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(inputDate) && DateTime.fromISO(inputDate).toFormat('FFF') !== 'Invalid DateTime';
    let dtStatus: boolean = false;
    let hasExpirationDateExpired = (inputDate: string ) => {
        if (DateTime.fromISO(dt).diff(DateTime.now(), 'days').days > 0) {
            return false;
          } 
          
            return true;
    }
    /*var dtStatus = dt != "" && dt != "null";
    var expDt = new Date();
     try {
        expDt = new Date(dt);
    }
    catch {
        dtStatus = false;
    }
    if (!dtStatus) {
        dt = "Invalid";
    }
    else {
        var today = new Date();
        if (expDt <= today) {
            dtStatus = false;
            dt = 'Expired';
            
        }
    } */
    if (isValidISODate(dt)) {
      if (DateTime.fromISO(dt).diff(DateTime.now(), 'days').days > 0) {
        dtStatus = true;
      } else {
        dtStatus = false;
      }
    }
    if (dtStatus && lotStatus) {
        instructions1 = "For Emergency Use Authorization (EUA) Only. In vitro diagnostic use only.";
        instructions2 = "Carefully read the instructions prior to starting test. It is recommended that gloves (not provided) be used during testing.";
    }
    else if (isValidISODate(dt) && !lotStatus && dtStatus) {
        instructions1 = "The lot number passed is invalid. Please rescan the QR code on the test instruction package and try again.";
        instructions2 = "";
    }
    else if (isValidISODate(dt) && !lotStatus && !dtStatus) {
        instructions1 = "The expiration date has expired for this test. Please obtain a new test kit and scan the QR code on the external packaging to view the instructions on this page.";
        instructions2 = "The lot number passed is also invalid, you can rescan the QR code on the test instruction package and try again.";
    }
    else if (lotStatus && !isValidISODate(dt)) {
        instructions1 = "The expiration date value passed is invalid. Please rescan the QR code on the test instruction package and try again.";
        instructions2 = "";
    }
    else if (!dtStatus && lotStatus) {
        instructions2 = "The expiration date has expired for this test.  Please obtain a new test kit and scan the QR code on the external packaging to view the instructions on this page."
    }
    else if (!lotStatus && !isValidISODate(dt)) {
      instructions1 = "The lot number and expiration date value passed are invalid. Please rescan the QR code on the test instruction package and try again.";
    }
    
    return (
    <div className={Styles['container']}>
      <div className={Styles['logo-maxim']} />
      <div className={Styles['logo-clear-detect']} />
      <div>
        <div className={Styles['qr-info']}>
          <ul style={{ fontSize: '12px'}}>
            <li>
                <span>Expiration Date:</span> { isValidISODate(dt) ? hasExpirationDateExpired(dt) ? DateTime.fromISO(dt).toFormat('MM/dd/yyyy') === 'Invalid DateTime' ? 'Invalid' : 'Expired': DateTime.fromISO(dt).toFormat('MM/dd/yyyy') + ' or ' + DateTime.fromISO(dt).toFormat('MMMM d, yyyy') : 'Invalid'}
            </li>
            <li>
                <span>Lot Number:</span> {lot} 
            </li>
          </ul>
        </div>
        <p>{instructions1}</p>
        <p>{instructions2}</p>
        </div>

        <div className={Styles['container-button']}>
            {lotStatus && dtStatus ? (
                <>
                <button
                    className={ClassNames(Styles.btn, Styles.regular, Styles.primary)}
                    onClick={() => window.open('https://cleardetect.bluesun.space/video')}
                >
                    Video Instructions
                </button>
                <button
                style={{marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                className={ClassNames(Styles.btn, Styles.regular, Styles.primary)}
                onClick={() => window.open('https://cleardetect.bluesun.space')}
            >
                View Printed Instructions
            </button>
                <button
                style={{marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                className={ClassNames(Styles.btn, Styles.regular, Styles.primary)}
                onClick={() => window.open('https://makemytestcount.org')}

            >
                Report Results
            </button>
                
                </>
                ) : null}
        </div>
        
        {/* <div>
        <h3>Manufacture Date: {new URLSearchParams(
        window.location.search
      ).get('dt')}</h3>
        <h3>Lot Number: {new URLSearchParams(
        window.location.search
      ).get('lot')}</h3>
        </div> */}
           
    </div>
  );
}

export default Home;