import React from 'react';
// import Carousel from '../Carousel';
import { useNavigate } from 'react-router-dom';
import ClassNames from 'clsx';
import Styles from './pg4.module.scss';
import packageJson from '../../package.json';
import IconSwipe from '../../src/assets/images/icon-swipe.svg';
import Step1 from '../../src/assets/images/stand-up-tube@2x.png';
import Step2 from '../../src/assets/images/open-swab@2x.png';
import Step3 from '../../src/assets/images/swab-nostrils@2x.png';
import Step4 from '../../src/assets/images/swab-other@2x.png';
import Step5 from '../../src/assets/images/insert-swab@2x.png';

const PG4 = () => {
    const navigate = useNavigate();
    const lot: string = `?lot=${new URLSearchParams(window.location.search).get('lot')}`;
    const dt =`&dt=${new URLSearchParams(window.location.search).get('dt')}`; 
    const searchParam:string = lot + dt;
    // const [currentSlide, setCurrentSlide] = React.useState<number>(1);
    return (
        // <div>
        //     <h2>Page 4</h2>
        //     <h3> {currentSlide} of 10</h3>
        //     <Carousel setCurrentSlide={setCurrentSlide}></Carousel>
        //     <Button variant="contained" type="button" onClick={() => navigate('/pg3')}>Back</Button>
        // </div>
       <>
            <video src="videos/video.mp4" width="320" height="240" controls autoPlay itemType='video/mp4'></video>
            <div className={Styles['container-button']}>
                <button
                    className={ClassNames(Styles.btn, Styles.regular, Styles.secondary)}
                    onClick={() => navigate('/pg3' + searchParam)}
                >
                    Back
                </button>

            </div>
           
       </>
    )
};

export default PG4;